<template>
  <div v-if="value" class="loader__backdrop">
    <div class="loader"></div>
  </div>

</template>

<script>
export default {
  name: "UiLoader",

  props: {
    value: {
      type: Boolean,
      default: false
    }

  }
}
</script>

<style lang="scss" scoped>
.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 5px solid #E21F1F;
    animation: prixClipFix 2s linear infinite ;
  }

  &__backdrop {
    display: flex;
    justify-content: center;
  }
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
</style>
