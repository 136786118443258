import { render, staticRenderFns } from "./UiLoader.vue?vue&type=template&id=5df81f63&scoped=true&"
import script from "./UiLoader.vue?vue&type=script&lang=js&"
export * from "./UiLoader.vue?vue&type=script&lang=js&"
import style0 from "./UiLoader.vue?vue&type=style&index=0&id=5df81f63&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df81f63",
  null
  
)

export default component.exports